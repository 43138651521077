.img-plus{
    position:absolute;
    bottom:270px;
    right:-15px;
    padding: 10px !important;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
.border-style-groove{
    border-style:groove;
    margin: 0 !important;
}
.border-radius-50{
    width: 70px;
    height: 70px;
    display: inline;
    margin: 0px auto;
    object-fit: cover;
    border-radius: 50%;
}